<template>
  <b-modal
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      centered
      v-model="localModalShow"
      title="Selecione os destinatários"
  >
    <div class="d-flex flex-column justify-center-center my-2">
      <div class="scrollable-list w-100">
        <b-list-group class="w-100">
          <b-list-group-item v-for="(user, index) in usersData" :key="index"
                             class="flex-column align-items-start"
          >
            <div class="d-flex align-items-center user-row">
              <b-form-checkbox
                  class="checkbox-user"
                  v-model="user.selected"
              >
              </b-form-checkbox>
              <div class="ml-2" @click="() => toggleSelectUser(user)">
                <div class="d-flex w-100">
                  <h5>
                    {{ user.name }} - <span class="opacity-50"> {{ user.perfil }}</span>
                  </h5>
                </div>
                <b-card-text>
                  {{user.email}}
                </b-card-text>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
      <div class="d-flex mt-2 w-100 justify-content-between">
        <div class="d-flex">
          <b-button :disabled="loading" variant="outline-primary" @click.prevent="selectAll" class="mr-1 btn-sm" >
            Marcar Todos
          </b-button>
          <b-button :disabled="loading" variant="outline-danger" @click.prevent="unselectAll" class="btn-sm">
            Desmarcar Todos
          </b-button>
        </div>
        <div class="d-flex ">
          <b-button :disabled="loading" variant="primary-button" @click="confirmAction" >
            <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Continuar
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>


<script>
import {BModal, BButton, BListGroup, BListGroupItem, BFormCheckbox, BCardText} from 'bootstrap-vue';

export default {
  components: {
    BFormCheckbox,
    BModal, BButton, BListGroup, BListGroupItem, BCardText
  },

  props: {
    modalShow: {
      required: true,
      type: Boolean
    },
    defaultCheckedEmails: {
      type: Boolean,
      default: false
    },
    users: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      loading: false,
      usersData: [],
      localModalShow: this.modalShow
    }
  },

  methods: {
    confirmAction(modalEvent) {
      modalEvent.preventDefault();

      this.loading = true;

      this.$emit('confirmed', this.usersData.filter(user => user.selected)
      );
    },

    selectAll() {
      this.usersData.forEach(user => {
        user.selected = true;
      });
    },

    unselectAll() {
      this.usersData.forEach(user => {
        user.selected = false;
      });
    },

    toggleSelectUser(user) {
      user.selected = !user.selected;
    },

    cancelAction(modalEvent) {
      modalEvent.preventDefault();

      this.$emit('canceled', modalEvent);
    },
  },

  watch: {
    modalShow(val) {
      this.loading = false;
      this.localModalShow = val;
    },
    users() {
      this.usersData = this.users.map(user => ({
        ...user,
        selected: this.defaultCheckedEmails
      }));
    },
    localModalShow(val) {
      if (!val) {
        this.$emit("closeModal");
      }
    }
  }
}
</script>

<style scoped>
.scrollable-list {
  max-height: 300px;
  overflow-y: auto;
  border-radius: 4px;
}
.user-row {
  cursor: pointer;
}
.checkbox-user {
  cursor: pointer !important;
}

.scrollable-list.w-100 {
  box-shadow: #f1f1f1 0px 5px 15px 5px;
}
</style>
