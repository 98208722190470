var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_c('SucessoModal',{attrs:{"itemIdentifier":"Agendamento","modalShow":_vm.modalSuccess.showModal,"typeModal":_vm.modalSuccess.typeModal,"caseModal":_vm.modalSuccess.caseModal},on:{"confirmed":_vm.redirectToList,"canceled":_vm.closeModal}}),_c('NewAddressModal',{attrs:{"showModal":_vm.modalNewAddress.showModal},on:{"confirmed":_vm.addNewAddress,"canceled":_vm.closeModalNewAddress}}),_c('ConfirmModal',{attrs:{"itemIdentifier":"agendamento","modalShow":_vm.modalCancel.showModal,"typeModal":_vm.modalCancel.typeModal},on:{"confirmed":_vm.redirectToList,"canceled":_vm.closeModalCancel}}),_c('ConfirmCampanhaNaoVigenteModal',{attrs:{"modal-show":_vm.showModalCampanhaNaoVigente},on:{"confirmed":function () { return _vm.confirmedCampanhaNaoVigente(_vm.editAgendamento); },"canceled":_vm.canceledCampanhaNaoVigente}}),_c('ErrorModal',{attrs:{"modalShow":_vm.modalError.showModal,"typeModal":_vm.modalError.typeModal,"caseModal":_vm.modalError.caseModal},on:{"confirmed":_vm.redirectToList}}),_c('SelectEmailsModal',{attrs:{"modal-show":_vm.showModalSelectEmails,"users":_vm.recipients,"defaultCheckedEmails":_vm.defaultCheckedEmails},on:{"confirmed":_vm.confirmSelectEmails,"closeModal":_vm.closeModalSelectEmails}}),_c('PageHeader',{attrs:{"screenName":_vm.screenName,"linkItems":_vm.linkItems}}),_c('main',{staticClass:"card hidden_overflow_tabela pt-2 px-1 pb-2"},[_c('validation-observer',{ref:"agendamentoForm"},[_c('b-form',[_c('b-overlay',{staticClass:"w-100 mt-1",attrs:{"show":_vm.loading,"rounded":"lg","opacity":"0.6"}},[_c('b-row',{staticClass:"pt-2 px-2"},[_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"CNPJ*","label-for":"cnpj","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"cnpj","rules":"required|length:18"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],staticClass:"form-control",attrs:{"id":"cnpj","placeholder":"00.000.000/0000-00","name":"cnpj","disabled":""},model:{value:(_vm.form.cnpj),callback:function ($$v) {_vm.$set(_vm.form, "cnpj", $$v)},expression:"form.cnpj"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nome Fantasia*","label-for":"nome_fantasia","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"Nome fantasia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"nome_fantasia","name":"nome_fantasia","disabled":""},model:{value:(_vm.form.nome_fantasia),callback:function ($$v) {_vm.$set(_vm.form, "nome_fantasia", $$v)},expression:"form.nome_fantasia"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Razão Social*","label-for":"razao_social","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"Razão Social","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"razao_social","name":"razao_social","disabled":""},model:{value:(_vm.form.razao_social),callback:function ($$v) {_vm.$set(_vm.form, "razao_social", $$v)},expression:"form.razao_social"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1)],1),_c('b-row',{staticClass:"pt-1 px-2"},[_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Endereço*","label-for":"endereco","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"endereço","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"endereco","options":_vm.enderecoOptions,"placeholder":"Selecione uma opção","label":"descricao","item-text":"endereco","item-value":"endereco","clearable":false},on:{"input":_vm.handleEnderecoSelecionado},scopedSlots:_vm._u([{key:"option",fn:function(data){return [_c('span',{class:{
                    'text-danger font-weight-bold': data.descricao_identificador === 'Outro'
                  }},[_c('b',[_vm._v(_vm._s(data.descricao_identificador))]),_c('br'),_vm._v(_vm._s(data.descricao))])]}},{key:"selected-option",fn:function(data){return [_c('span',[_c('b',[_vm._v(_vm._s(data.descricao_identificador))]),_c('br'),_vm._v(_vm._s(data.descricao))])]}}],null,true),model:{value:(_vm.form.endereco),callback:function ($$v) {_vm.$set(_vm.form, "endereco", $$v)},expression:"form.endereco"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(_vm.form.errorApi.id_empresa_endereco)?_c('small',{staticClass:"text-danger"},[_vm._v(" O campo endereço é obrigatório ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Unidade Operacional*","label-for":"Unidade Operacional","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"unidade operacional","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"unidade_operacional","options":_vm.unidadeOperacionalOptions,"placeholder":"Selecione uma opção","label":"descricao","item-text":"unidade operacional","item-value":"unidade_operacional","clearable":false},model:{value:(_vm.form.unidade_operacional),callback:function ($$v) {_vm.$set(_vm.form, "unidade_operacional", $$v)},expression:"form.unidade_operacional"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(_vm.form.errorApi.id_unidade)?_c('small',{staticClass:"text-danger"},[_vm._v(" O campo unidade é obrigatório ")]):_vm._e()]}}])})],1)],1)],1),_c('b-row',{staticClass:"pt-1 px-2"},[_c('b-col',{attrs:{"lg":"2","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Data de início*","label-for":"data_inicio","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"data de início","rules":{ required: true, date_format: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],ref:"dataAplicacao",staticClass:"form-control",attrs:{"id":"data_inicio","placeholder":"00/00/0000","autocomplete":"off"},model:{value:(_vm.form.data_aplicacao),callback:function ($$v) {_vm.$set(_vm.form, "data_aplicacao", $$v)},expression:"form.data_aplicacao"}}),_c('b-input-group-append',[_c('b-form-datepicker',{staticClass:"form-control d-flex align-items-center",attrs:{"locale":"pt-BR","button-only":"","right":"","hide-header":true,"label-help":"Selecione a data de início","button-variant":"custom-blue","clearable":"","menu-class":"custom-datepicker-menu"},model:{value:(_vm.data_aplicacao),callback:function ($$v) {_vm.data_aplicacao=$$v},expression:"data_aplicacao"}})],1)],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e(),(_vm.form.errorApi.data_aplicacao)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.data_aplicacao)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"2","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Data de término*","label-for":"data_termino","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"data de término","rules":{ required: true, date_format: true, after_start_date: _vm.form.data_aplicacao }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],ref:"dataTermino",staticClass:"form-control",attrs:{"id":"data_termino","placeholder":"00/00/0000","autocomplete":"off"},model:{value:(_vm.form.data_final_aplicacao),callback:function ($$v) {_vm.$set(_vm.form, "data_final_aplicacao", $$v)},expression:"form.data_final_aplicacao"}}),_c('b-input-group-append',[_c('b-form-datepicker',{staticClass:"form-control d-flex align-items-center",attrs:{"locale":"pt-BR","button-only":"","right":"","hide-header":true,"label-help":"Selecione a data de término","button-variant":"custom-blue","clearable":"","menu-class":"custom-datepicker-menu"},model:{value:(_vm.data_final_aplicacao),callback:function ($$v) {_vm.data_final_aplicacao=$$v},expression:"data_final_aplicacao"}})],1)],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e(),(_vm.form.errorApi.data_final_aplicacao)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.data_final_aplicacao)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"2","md":"2","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Horário de início","label-for":"horario_inicio","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"horário de início","rules":_vm.horarioValido,"custom-messages":{
              regex: 'O campo horário de início deve estar no formato HH:MM'
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"}],staticClass:"form-control",attrs:{"id":"horario_inicio","placeholder":"00:00","autocomplete":"off"},model:{value:(_vm.form.horario_inicio),callback:function ($$v) {_vm.$set(_vm.form, "horario_inicio", $$v)},expression:"form.horario_inicio"}}),_c('b-input-group-append',[_c('b-form-timepicker',{staticClass:"form-control d-flex align-items-center",attrs:{"locale":"pt-BR","no-close-button":"","button-only":"","right":"","hide-header":true,"label-help":"Selecione o horário de início","button-variant":"custom-blue","clearable":""},model:{value:(_vm.form.horario_inicio),callback:function ($$v) {_vm.$set(_vm.form, "horario_inicio", $$v)},expression:"form.horario_inicio"}})],1)],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e(),(_vm.form.errorApi.horario_inicio)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.horario_inicio)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"2","md":"2","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Horário de término","label-for":"horario_termino","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"horário de término","rules":_vm.horarioValido,"custom-messages":{
              regex: 'O campo horário de término deve estar no formato HH:MM'
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"}],staticClass:"form-control",attrs:{"id":"horario_termino","placeholder":"00:00","autocomplete":"off"},model:{value:(_vm.form.horario_termino),callback:function ($$v) {_vm.$set(_vm.form, "horario_termino", $$v)},expression:"form.horario_termino"}}),_c('b-input-group-append',[_c('b-form-timepicker',{staticClass:"form-control d-flex align-items-center",attrs:{"locale":"pt-BR","no-close-button":"","button-only":"","right":"","hide-header":true,"label-help":"Selecione o horário de término","button-variant":"custom-blue","clearable":""},model:{value:(_vm.form.horario_termino),callback:function ($$v) {_vm.$set(_vm.form, "horario_termino", $$v)},expression:"form.horario_termino"}})],1)],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e(),(_vm.form.errorApi.horario_termino)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.horario_termino)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Período","label-for":"periodo","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"período"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"periodo","options":_vm.periodoOptions,"placeholder":"Selecione um período","label":"descricao","multiple":"","item-text":"periodo","item-value":"periodo","clearable":false},model:{value:(_vm.form.periodo),callback:function ($$v) {_vm.$set(_vm.form, "periodo", $$v)},expression:"form.periodo"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Nenhuma opção selecionável. ")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1)],1),_c('b-row',{staticClass:"pt-1 px-2"},[_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Selecione uma campanha","label-for":"campanha","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"campanha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"campanha","options":_vm.campaignsOptions,"placeholder":"Selecione a campanha","label":"descricao","item-text":"campanha","item-value":"campanha","clearable":false},on:{"input":_vm.setDosesPrevistas},model:{value:(_vm.form.campaign),callback:function ($$v) {_vm.$set(_vm.form, "campaign", $$v)},expression:"form.campaign"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(_vm.form.errorApi.id_campanha)?_c('small',{staticClass:"text-danger"},[_vm._v(" O campo campanha é obrigatório ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"2","md":"2","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Doses previstas*","label-for":"doses_previstas","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"doses previstas","rules":"required|max:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#########'),expression:"'#########'"}],staticClass:"form-control",attrs:{"id":"doses_previstas","name":"doses_previstas","placeholder":"--"},model:{value:(_vm.form.doses_previstas),callback:function ($$v) {_vm.$set(_vm.form, "doses_previstas", $$v)},expression:"form.doses_previstas"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"2","md":"2","sm":"12"}},[_c('validation-provider',{attrs:{"name":"doses aplicadas","rules":"max:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({title: 'O número de doses aplicadas será atualizado conforme os colaboradores forem vacinados', customClass: 'custom-tooltip'}),expression:"{title: 'O número de doses aplicadas será atualizado conforme os colaboradores forem vacinados', customClass: 'custom-tooltip'}",modifiers:{"hover":true}}],attrs:{"label":"Doses aplicadas","label-for":"doses_aplicadas","label-class":"font_size_label"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.form.doses_aplicadas > 0 ? null : _vm.numeroMask),expression:"form.doses_aplicadas > 0 ? null : numeroMask"}],staticClass:"form-control",attrs:{"id":"doses_aplicadas","disabled":true,"name":"doses_aplicadas","placeholder":"Ex: 2000"},model:{value:(_vm.form.doses_aplicadas),callback:function ($$v) {_vm.$set(_vm.form, "doses_aplicadas", $$v)},expression:"form.doses_aplicadas"}})],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Status","label-for":"status","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"status","options":_vm.statusOptions,"placeholder":"Selecione o status","label":"status","item-text":"Status","item-value":"status","clearable":false},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1)],1),_c('b-row',{staticClass:"pt-1 px-2"},[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Observações","label-for":"observacoes","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"ponto de atendimento","rules":"max:500"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"observacoes","placeholder":"Ex: Agendamento realizado em função de ...","rows":"5","state":_vm.form.observacoes.length > 500 ? false : null},model:{value:(_vm.form.observacoes),callback:function ($$v) {_vm.$set(_vm.form, "observacoes", $$v)},expression:"form.observacoes"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1)],1)],1),_c('b-row',{staticClass:"pt-1 px-2"},[_c('b-col',[_c('b-button',{staticClass:"mr-2",attrs:{"id":"save_agendamento","variant":"custom-blue","disabled":_vm.isDisabled},on:{"click":function($event){$event.preventDefault();return _vm.editAgendamento.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Salvar alterações")]),(_vm.loading)?_c('span',{staticClass:"spinner-border spinner-border-sm ml-1",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e()],1),_c('b-button',{attrs:{"id":"cancel-register-departamento","disabled":_vm.loading,"variant":"outline-danger"},on:{"click":function($event){$event.preventDefault();return _vm.openModalCancel.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"XIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Cancelar")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }