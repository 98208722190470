<template>
    <b-modal id="new-address-modal" no-close-on-esc no-close-on-backdrop hide-header hide-footer centered
        v-model="showModal">
        <div class="modal-spinner" v-if="loading">
            <b-spinner />
        </div>

        <div v-if="!loading" class="d-flex flex-column my-2">
            <h2>Cadastrar novo endereço</h2>

            <validation-observer ref="itemRules">
                <b-form>
                    <b-row>
                        <b-col lg="12" md="12" sm="12">
                            <b-form-group label="CEP" label-for="cep-input" label-class="font_size_label_empresa">
                                <validation-provider #default="{ errors }" name="CEP" :rules="'required|length:8'"
                                :custom-messages="{
                                    required: 'O campo CEP é obrigatório.',
                                    length: 'O campo CEP deve conter 8 dígitos.'
                                }">
                                    <vue-cleave id="cep-input" v-model="value.cep" :raw="true" :options="mascaraCep"
                                        class="form-control" placeholder="00000-000" name="Cep"
                                        @input="() => validaCep()" :disabled="loadingShowCep" />
                                    <b-input-group-append v-if="loadingShowCep">
                                        <b-spinner small label="Carregando..."></b-spinner>
                                    </b-input-group-append>
                                    <small v-if="errors[0]" class="text-danger">
                                        {{ errors[0] }}
                                    </small>
                                    <small v-if="errors[1]" class="text-danger">
                                        {{ errors[1] }}
                                    </small>
                                    <small v-if="cepError.length > 0" class="text-danger">
                                        {{ cepError }}
                                    </small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col lg="12" md="12" sm="12">
                            <b-form-group label="Logradouro" label-for="Logradouro-input"
                                label-class="font_size_label_empresa">
                                <validation-provider #default="{ errors }" name="Logradouro" :rules="'required'">
                                    <b-form-input id="Logradouro-input" v-model="value.logradouro"
                                        :disabled="disabled.logradouro" placeholder="Logradouro"
                                        v-mask="('X'.repeat(200))" />
                                    <small v-if="errors[0]" class="text-danger">
                                        Este campo é obrigatório
                                    </small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col lg="12" md="12" sm="12">
                            <b-form-group label="Número" label-for="numero-input" label-class="font_size_label_empresa">
                                <validation-provider #default="{ errors }" name="Número">
                                    <b-form-input id="numero-input" v-model="value.numero" :disabled="disabled.numero"
                                        placeholder="Número" v-mask="('#'.repeat(6))" />
                                    <small v-if="errors[0]" class="text-danger">
                                        Este campo é obrigatório
                                    </small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col lg="12" md="12" sm="12">
                            <b-form-group label="Ponto de referência" label-for="ponto-de-referencia-input"
                                label-class="font_size_label_empresa">
                                <validation-provider #default="{ errors }" name="Ponto de Referência"
                                    >
                                    <b-form-input id="ponto-de-referencia-input" v-model="value.ponto_referencia"
                                        placeholder="Ponto de Referência" :disabled="disabled.ponto_referencia"
                                        v-mask="('X'.repeat(200))" />
                                    <small v-if="errors[0]" class="text-danger">
                                        Este campo é obrigatório
                                    </small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col lg="12" md="12" sm="12">
                            <b-form-group label="Bairro" label-for="bairro-input" label-class="font_size_label_empresa">
                                <validation-provider #default="{ errors }" name="Bairro" :rules="'required'">
                                    <b-form-input id="bairro-input" v-model="value.bairro" :disabled="disabled.bairro"
                                        placeholder="Bairro" v-mask="('X'.repeat(200))" />
                                    <small v-if="errors[0]" class="text-danger">
                                        Este campo é obrigatório
                                    </small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col lg="12" md="12" sm="12">
                            <b-form-group label="Estado" label-for="estado-input" label-class="font_size_label_empresa">
                                <validation-provider #default="{ errors }" name="Estado" :rules="'required'">
                                    <v-select id="estados-input" @input="preencheCidades" :options="estados"
                                        v-model="value.uf" :clearable="!disabled.uf" :disabled="disabled.uf"
                                        placeholder="Selecione o Estado" label="nome" item-text="nome" item-value="uf">
                                        <span slot="no-options">Nenhuma opção selecionável.</span>
                                    </v-select>
                                    <small v-if="errors[0]" class="text-danger">
                                        Este campo é obrigatório
                                    </small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col lg="12" md="12" sm="12">
                            <b-form-group label="Cidade" label-for="cidade-input" label-class="font_size_label_empresa">
                                <validation-provider #default="{ errors }" name="Cidade" :rules="'required'">
                                    <v-select id="cidades-input" :clearable="!disabled.cidade"
                                        :disabled="disabled.cidade" :options="cidades" v-model="value.cidade"
                                        placeholder="Selecione a cidade" label="nome" item-text="nome"
                                        item-value="id_cidade">
                                        <span slot="no-options">Nenhuma opção selecionável.</span>
                                    </v-select>
                                    <small v-if="errors[0]" class="text-danger">
                                        Este campo é obrigatório
                                    </small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </div>
        <section v-if="!loading" class="d-flex justify-content-center mb-1">
            <b-button id="save-entrada" class="mr-2 mb-2-sm" variant="custom-blue" @click.prevent="confirmAction()"
                :disabled="saving">
                <feather-icon icon="CheckIcon" class="mr-50" />
                <span class="align-middle">Inserir endereço</span>
                <output v-if="saving" class="spinner-border spinner-border-sm ml-1" aria-live="polite"
                    aria-atomic="true">
                    Saving...
                </output>
            </b-button>
            <b-button id="cancel-entrada" :disabled="saving" variant="outline-danger" @click.prevent="cancelAction">
                <feather-icon icon="XIcon" class="mr-50" />
                <span class="align-middle">Descartar</span>
            </b-button>
        </section>
    </b-modal>
</template>

<script>
import {
    BModal,
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    VBTooltip,
    BSpinner,
    BCard,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BAlert,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueCleave from "vue-cleave-component";
import { required, min } from "@validations";
import vSelect from "vue-select";

export default {
    components: {
        BModal,
        BForm,
        BCol,
        BRow,
        BFormGroup,
        BFormInput,
        BButton,
        BTable,
        VBTooltip,
        BSpinner,
        BCard,
        vSelect,
        BFormDatepicker,
        BInputGroup,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
        VueCleave,
        BAlert,
    },
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            required,
            min,
            loadingShowCep: false,
            cepError: '',
            estados: [],
            cidades: [],
            loading: false,
            mascaraCep: {
                delimiters: ['-'],
                blocks: [5, 3],
                numericOnly: true
            },
            disabled: {
                logradouro: true,
                numero: true,
                bairro: true,
                cidade: true,
                uf: true,
                ponto_referencia: true
            },
            value: {
                cep: '',
                logradouro: '',
                numero: '',
                bairro: '',
                cidade: '',
                uf: '',
                ponto_referencia: ''
            },
            saving: false,
        };
    },
    watch: {
        'value.uf'(newUf) {
            if (!newUf) {
                this.value.cidade = null;
                this.cidades = [];
            }
        }
    },
    mounted() {
        this.buscaEstados();
    },
    methods: {
        confirmAction() {
            this.$refs.itemRules.validate().then((success) => {
                if (success) {
                    let formattedItem = {
                        cep: this.value.cep,
                        logradouro: this.value.logradouro,
                        numero: this.value.numero,
                        bairro: this.value.bairro,
                        cidade: {
                            id_cidade: this.value.cidade.id_cidade,
                            nome: this.value.cidade.nome,
                            uf: this.value.uf?.nome || this.value.uf
                        },
                        ponto_referencia: this.value.ponto_referencia
                    };

                    this.$emit("confirmed", formattedItem);
                    this.emptyForm();
                    this.$refs.itemRules.reset();
                }
            });
        },
        cancelAction(modalEvent) {
            modalEvent.preventDefault();
            this.$emit("canceled", modalEvent);
            this.emptyForm();
            this.$refs.itemRules.reset();
        },
        emptyForm() {
            this.value = {
                cep: '',
                logradouro: '',
                numero: '',
                bairro: '',
                cidade: '',
                uf: '',
                ponto_referencia: ''
            };

            this.cepError = '';

            this.disabled = {
                logradouro: true,
                numero: true,
                bairro: true,
                cidade: true,
                uf: true,
                ponto_referencia: true
            };
        },
        async buscaEstados() {
            if (!this.value.cep) {
                const { data } = await this.$http.get(this.$api.getEstados());
                this.estados = data;
            }
        },
        validaCep() {
            this.cepError = '';
            if (this.value.cep && this.value.cep.length == 8) {
                this.buscaCep(this.value.cep);
            }
        },
        async buscaCep(cep) {
            this.loadingShowCep = true;

            await this.$http.get(this.$api.cidadesBuscarPorCep(cep), {
                params: {
                    idEmpresa: this.isEdit ? this.idCompany : null
                }
            }).then(async (res) => {
                let cepInfo = res.data;

                if (cepInfo && Object.keys(cepInfo).length !== 0 && Object.getPrototypeOf(cepInfo) === Object.prototype) {
                    this.cepError = '';

                    this.disabled.logradouro = !!cepInfo.logradouro;
                    this.disabled.bairro = !!cepInfo.bairro;
                    this.disabled.cidade = true;
                    this.disabled.uf = true;

                    this.value.bairro = cepInfo.bairro;
                    this.value.logradouro = cepInfo.logradouro;
                    this.value.uf = cepInfo.estado.nome;
                    this.value.cidade = {
                        id_cidade: cepInfo.id_cidade,
                        nome: cepInfo.nome,
                    }
                    this.disabled.ponto_referencia = false;
                    this.disabled.numero = false;
                    this.loadingShowCep = false;
                } else {
                    this.disabled.logradouro = false;
                    this.disabled.bairro = false;
                    this.disabled.numero = false;
                    this.disabled.ponto_referencia = false;
                    this.disabled.cidade = false;
                    this.disabled.uf = false;
                    this.loadingShowCep = false;
                    this.cepError = "Não foi possível encontrar o cep, mas pode continuar o preenchimento dos dados";
                }
            }).catch((errors) => {
                this.$toast.error(errors.response.data.message);
            });
        },
        async buscaCidades(uf) {
            if (!this.value.cidade) {
                const { data } = await this.$http.get(this.$api.getCidadesDoEstado(uf));
                this.cidades = data.cidades;
            }
        },

        preencheCidades(estado) {
            if (estado) {
                this.buscaCidades(estado.uf);
            } else {
                this.cidades = null;
            }
        },
    },
};
</script>

<style scoped>
.bloco {
    height: 200px;
}

.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

.panel-enter-active,
.panel-leave-active {
    transition: all 0.5s ease;
}

.panel-enter-from,
.panel-leave-to {
    opacity: 0;
    height: 0;
    transform: translateX(30px);
}

.modal-spinner {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>